<template>
  <div class="type-page">
    <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
    <el-table :data="tableData" max-height="500">
      <el-table-column prop="typeName" label="类型名称" />
<!--      <el-table-column prop="childNum" label="产品统计" />-->
      <el-table-column prop="createTime" label="创建时间" :formatter="timeFormatter" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="是否删除" @confirm="handleDelete(scope.row.id)">
            <el-button size="mini" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增产品类型" :visible.sync="visible" width="30%" @closed="handleClosed">
      <el-form>
        <el-form-item label="产品类型名称">
          <el-input v-model="typeName" type="text" />
        </el-form-item>
        <el-form-item label="上级产品类型名称">
          <el-select v-model="parentId" placeholder="请选择" style="width: 100%;">
            <el-option v-for="type of tableData" :key="type.id" :label="type.typeName" :value="type.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button :disabled="isEmpty" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  
    <el-dialog title="修改产品类型" :visible.sync="updateVisible" width="30%" @closed="handleClosed">
      <el-form>
        <el-form-item label="产品类型名称">
          <el-input v-model="typeName" type="text" />
        </el-form-item>
        <el-form-item label="上级产品类型名称">
          <el-select v-model="parentId" placeholder="请选择" style="width: 100%;">
            <el-option v-for="type of tableData" :key="type.id" :label="type.typeName" :value="type.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button :disabled="isEmpty" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import dayjs from 'dayjs'
  export default {
    data() {
      return {
        visible: false,
        updateVisible: false,
        typeId: null,
        typeName: '',
        parentId: '',
        tableData: [],
      }
    },
    computed: {
      isEmpty() {
        const { typeName } = this
        return !typeName
      }
    },
    mounted() {
      this.getTypeList()
    },
    methods: {
      handleAdd() {
        this.visible = true
      },
      handleSubmit() {
        const { typeId, typeName, parentId } = this
        if (typeName.length === 0) {
          this.$notify.warning('名称不能为空')
        } else {
          // 新增提交
          if (this.visible) {
            axios.post('/api/type', { typeName: typeName, parentId: parentId }).then(data => {
              if (data.data === 'success') {
                this.$notify.success('新增成功')
                this.visible = false
                this.getTypeList()
              } else {
                this.$notify.error('新增失败')
              }
            })
          }
          //修改提交
          if (this.updateVisible) {
            axios.put('/api/type', { id: typeId, typeName: typeName, parentId: parentId }).then(data => {
              if (data.data === 'success') {
                this.$notify.success('修改成功')
                this.updateVisible = false
                this.getTypeList()
              } else {
                this.$notify.error('修改失败')
              }
            })
          }
        }
      },
      handleEdit(row) {
        this.typeId = row.id
        this.typeName = row.typeName
        this.parentId = row.parentId
        this.updateVisible = true
      },
      handleDelete(id) {
        axios.delete(`/api/type/${id}`,).then(data => {
          if (data.data === 'success') {
            this.$notify.success('删除成功')
            this.getTypeList()
          } else {
            this.$notify.error('删除失败')
          }
        })
      },
      handleClosed() {
        this.typeName = ''
        this.parentId = ''
      },
      getTypeList() {
        axios.get('/api/type').then(data => {
          this.tableData = data.data
        })
      },
      timeFormatter(row, column, cellValue, index) {
        return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
</script>
